<template>
  <footer class="brand-footer d-flex mt-auto">
    <div class="d-block ml-auto mr-3 d-flex align-items-center">
      <span class="mr-3">Powered by</span>
      <logo-small />
    </div>
  </footer>
</template>

<script>
import LogoSmall from "../logo/LogoSmall";
export default {
  components: {
    LogoSmall
  }
};
</script>

<style lang="scss" scoped>
.brand-footer {
  min-height: 3rem;
}
</style>
