<template v-if="error">
  <p class="error-text ms-fontColor-sharedRed20 text-wrap mb-4">
    {{ error }}
  </p>
</template>

<script>
export default {
  computed: {
    error() {
      return this.$store.state.error;
    }
  }
};
</script>
