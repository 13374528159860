<template>
  <div>
    <div class="position-relative">
      <input
        v-model="newPassword"
        @keyup="newPasswordInput()"
        id="newPassword"
        name="newPassword"
        class="fluent py-1 mt-2 w-100"
        :type="[passwordShown ? 'text' : 'password']"
        placeholder="New password"
        aria-label="New password"
      />
      <span
        @click="togglePasswordVisibilty"
        :class="[passwordShown ? 'fa-eye-slash' : 'fa-eye']"
        class="password-visibility fal ms-fontColor-sharedGray40"
      ></span>
    </div>
    <div class="d-flex flex-column">
      <meter
        class="password-strength mb-2 w-100"
        max="4"
        :value="measuredPasswordStrength"
      />
      <span v-if="newPassword" class="ms-fontColor-sharedGray30"
        >{{ passwordStrengthInfo[measuredPasswordStrength] }} password</span
      >
      <ul class="mt-3 mx-2 mb-2 ms-fontColor-sharedGray30">
        <li>Use 12 characters or more</li>
        <li>Create a password that is unique for this website</li>
        <li>Consider using a passphrase or password manager</li>
      </ul>
    </div>
  </div>
</template>

<script>
const getZxcvbn = () => import("zxcvbn-lite").then(obj => obj.default); // return default object, with lazy loading

export default {
  data: function() {
    return {
      newPassword: "",
      showPassword: false,
      passwordStrength: "",
      passwordStrengthInfo: ["Bad", "Very weak", "Weak", "Good", "Strong"]
    };
  },
  watch: {
    async newPassword(newValue) {
      const zxcvbn = await getZxcvbn();
      this.passwordStrength = zxcvbn(newValue);
    }
  },
  computed: {
    measuredPasswordStrength() {
      if (this.passwordStrength) {
        const crackTimes = this.passwordStrength.crack_times_display;
        const totals = [
          crackTimes["online_throttling_100_per_hour"],
          crackTimes["offline_slow_hashing_1e4_per_second"],
          crackTimes["offline_slow_hashing_1e4_per_second"],
          crackTimes["offline_fast_hashing_1e10_per_second"]
        ].filter(ps => ps === "centuries");
        return totals.length;
      }
      return 0;
    },
    passwordShown() {
      return this.showPassword;
    }
  },
  methods: {
    newPasswordInput() {
      this.$emit("newpassword", this.newPassword);
    },
    togglePasswordVisibilty() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>

<style lang="scss" scoped>
.password-visibility {
  position: absolute;
  top: 12px;
  right: 0;

  &:hover {
    cursor: pointer;
  }
}
.fa-eye {
  margin-right: 1px;
}
</style>
