<template>
  <div class="overflow-hidden">
    <CenterCard>
      <LoginForm />
    </CenterCard>
  </div>
</template>

<script>
import CenterCard from "@/components/layout/CenterCard.vue";
import LoginForm from "@/components/forms/LoginForm.vue";

export default {
  name: "Login",
  components: {
    CenterCard,
    LoginForm
  }
};
</script>
