import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "alpinejs";
import "./assets/css/fluentstrap-2.1.3.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/light.min.css";
import "./assets/css/brands.min.css";

Vue.config.productionTip = false;

Vue.prototype.$settings = require("./assets/settings.json");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
