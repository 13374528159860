<template>
  <form @submit.prevent="submit">
    <form-error />
    <router-view
      @username="username = $event"
      @password="password = $event"
      @mfa="mfa = $event"
    >
    </router-view>
    <div class="d-flex mt-4">
      <button
        :disabled="buttonDisabled"
        type="submit"
        class="fluent ml-auto mt-2 mb-1 ms-fontColor-black ms-bgColor-sharedYellow10"
      >
        <span>{{ formButtonText }}</span>
      </button>
    </div>
  </form>
</template>

<script>
import FormError from "./partials/FormError.vue";
import { mapActions } from "vuex";
export default {
  components: {
    FormError
  },
  data: function() {
    return {
      username: "",
      password: "",
      mfa: "",
      mfaShown: ""
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.mfaShown = route.path.includes("mfa");
      },
      deep: true
    }
  },
  computed: {
    isMfaStep() {
      return this.mfaShown;
    },
    buttonDisabled() {
      return this.isMfaStep ? !this.mfa : !this.username || !this.password;
    },
    formButtonText() {
      return this.isMfaStep ? "Verify code" : "Sign in";
    }
  },
  methods: {
    ...mapActions(["postToApi"]),
    submit() {
      this.postToApi({
        url: "/login",
        mutation: "setView",
        data: {
          username: this.username,
          password: this.password,
          mfa: this.mfa
        }
      });
    }
  }
};
</script>

<style lang="scss">
.error-text {
  max-width: 25rem;
}
</style>
