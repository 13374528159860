<template>
  <div class="p-3" v-html="template"></div>
</template>

<script>
export default {
  props: {
    template: {
      type: String
    }
  }
};
</script>

<style></style>
