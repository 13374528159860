<template>
  <form @submit.prevent="submit">
    <form-error />
    <change-password-form @newpassword="newPassword = $event" />
    <div class="d-flex">
      <label class="align-self-center">
        <input
          type="checkbox"
          v-model="agreedToTerms"
          class="align-top-1 mr-1"
        />
        I agree to the
        <button
          class="fluent text-button mr-4"
          @click.prevent="modalShown = true"
        >
          Terms of Service
        </button>
      </label>
      <button
        :disabled="buttonDisabled"
        type="submit"
        class="fluent ml-auto px-2 mt-2 mb-1 ms-fontColor-black ms-bgColor-sharedYellow10"
      >
        <span>Finish registration</span>
      </button>
    </div>
    <modal v-model="modalShown" classes="w-75 terms-of-service-modal">
      <template #title-bar>
        <h2>Terms of Service</h2>
      </template>
      <terms-of-service />
      <template #button-bar>
        <div class="mt-2 d-flex align-items-center justify-content-end">
          <button
            class="fluent mr-3 ms-fontColor-white ms-bgColor-sharedGreen20"
            @click="
              () => {
                agreedToTerms = true;
                modalShown = false;
              }
            "
          >
            <span> Ok</span>
          </button>
          <button class="fluent ms-bgColor-gray40" @click="modalShown = false">
            Cancel
          </button>
        </div>
      </template>
    </modal>
  </form>
</template>

<script>
import Modal from "../Modal.vue";
import FormError from "./partials/FormError";
import ChangePasswordForm from "./partials/ChangePasswordPartial";
import TermsOfService from "../TermsOfService.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ChangePasswordForm,
    TermsOfService,
    FormError,
    Modal
  },
  data: function() {
    return {
      modalShown: false,
      newPassword: "",
      agreedToTerms: false
    };
  },
  computed: {
    buttonDisabled() {
      return (
        !this.newPassword ||
        this.newPassword.length < 12 ||
        this.agreedToTerms === false
      );
    }
  },
  methods: {
    ...mapActions(["postToApi"]),
    submit() {
      this.postToApi({
        url: "/activate-account",
        mutation: "setView",
        data: {
          newPassword: this.newPassword,
          agreedToTerms: this.agreedToTerms
        }
      });
    }
  }
};
</script>
