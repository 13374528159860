<template>
  <div
    v-if="modalState"
    class="overlay d-flex flex-column align-items-center justify-content-center"
  >
    <div :class="classes" class="ms-depth-64 ms-bgColor-white p-3">
      <div class="d-flex pb-2 bottom-shadow">
        <slot name="title-bar"></slot>
        <span
          @click="() => $emit('input', false)"
          class="ml-auto fluent close-button ms-fontColor-black font-weight-light"
        >
          <span class="fal fa-times"></span
        ></span>
      </div>
      <div class="slot left-shadow">
        <slot></slot>
      </div>
      <div class="top-shadow pt-2">
        <slot name="button-bar"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    classes: {
      type: String
    }
  },
  computed: {
    modalState() {
      return this.value;
    },
    setClasses() {
      return this.classes;
    }
  }
};
</script>

<style lang="scss" scoped>
.slot {
  overflow-y: scroll;
  max-height: 50vh;
}
</style>
