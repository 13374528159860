import { ApiResponse } from "@/types";
import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    route: "",
    error: "",
    mfaEnabled: false
  },
  mutations: {
    async setView(state, response: Response) {
      const bodyResponse = (await response.json()) as ApiResponse;
      state.route = bodyResponse.route;
      state.error = bodyResponse.error;
    },
    // Only MFA for now. >> split this in mfa route
    async setMfaSettings(state, response: Response) {
      const bodyResponse = (await response.json()) as { mfaEnabled: boolean };
      state.mfaEnabled = bodyResponse.mfaEnabled;
      if (state.mfaEnabled) state.route = "/home/settings";
      else state.error = "Verification code was not correct";
    },
    async setSettings(state, response: Response) {
      const bodyResponse = (await response.json()) as { mfaEnabled: boolean };
      state.mfaEnabled = bodyResponse.mfaEnabled;
    }
  },
  actions
  // modules: {
  // }
});
