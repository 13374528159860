<template>
  <div>
    <div class="ms-bgColor-gray10 vh-100 d-flex flex-column">
      <main-menu />
      <div class="ms-depth-8 ms-bgColor-white m-md-4 m-2">
        <router-view></router-view>
      </div>
      <pennions-footer v-if="$settings.rebranded" class="ms-bgColor-gray10" />
    </div>
  </div>
</template>

<script>
import MainMenu from "@/components/layout/MainMenu.vue";
import PennionsFooter from "@/components/layout/PennionsFooter";

export default {
  components: { MainMenu, PennionsFooter }
};
</script>
