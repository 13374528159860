<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    routerView() {
      return this.$store.state.route;
    }
  },
  watch: {
    routerView(routeValue) {
      this.$router.push(routeValue);
    }
  },
  beforeCreate() {
    document.getElementsByTagName("title")[0].innerText =
      this.$settings.appTitle || "Pennions";
  }
};
</script>

<style lang="scss"></style>
