<template>
  <center-card>
    <activate-account-form></activate-account-form>
  </center-card>
</template>

<script>
import CenterCard from "@/components/layout/CenterCard.vue";
import ActivateAccountForm from "@/components/forms/ActivateAccountForm.vue";
export default {
  components: { CenterCard, ActivateAccountForm }
};
</script>
