<template>
  <div class="d-flex flex-column">
    <nav class="bottom-shadow ms-bgColor-white w-100 d-flex align-items-center">
      <img
        v-if="$settings.appIcon"
        :src="`/${$settings.appIcon}`"
        :class="`${$settings.appIconClass} ml-3`"
      />
      <logo-small v-else class="ml-3" />
      <span class="pipe"></span>
      <app-title class="mx-3" />
      <router-link to="/home" class="fluent button">
        <span class="mr-2">Home</span>
      </router-link>
      <div class="p-1 ml-auto mr-2 d-flex position-relative">
        <button
          @click="settingsMenu = !settingsMenu"
          class="fluent transparent-button"
        >
          <span> <!-- add username --></span>
          <span class="fal fa-user-circle fa-2x"></span>
        </button>
        <nav
          v-if="showSettingsMenu"
          @mouseenter="removeOutsideClick()"
          @mouseleave="addOutsideClick()"
          @click="closeOnOutsideClick()"
          role="settings menu"
          class="settings-menu ms-bgColor-white left-bottom-shadow p-2 text-nowrap d-flex flex-column"
        >
          <router-link
            to="/home/settings"
            class="fluent button pl-1 mt-2 d-flex"
          >
            <span class="mr-2">Settings</span>
            <span class="fal fa-user-cog ml-auto"></span
          ></router-link>
          <router-link
            to="/home/terms-of-service"
            class="fluent button pl-1 my-2 d-flex"
          >
            <span class="mr-3">Terms of service</span>
            <span class="fal fa-file-contract fa-lg ml-auto mr-1"></span>
          </router-link>
          <hr />
          <button
            @click="signout"
            class="fluent transparent-button p-1 mt-2 d-flex"
          >
            <span>Sign out</span>
            <span class="fal fa-sign-out ml-auto"></span>
          </button>
        </nav>
      </div>
    </nav>
  </div>
</template>

<script>
import LogoSmall from "../logo/LogoSmall.vue";
import AppTitle from "../AppTitle.vue";
import { mapActions } from "vuex";

export default {
  components: { LogoSmall, AppTitle },
  data: function() {
    return {
      settingsMenu: false
    };
  },
  computed: {
    showSettingsMenu() {
      return this.settingsMenu;
    }
  },
  methods: {
    ...mapActions(["postToApi"]),
    addOutsideClick() {
      document.addEventListener("click", this.closeOnOutsideClick);
    },
    closeOnOutsideClick() {
      this.settingsMenu = false;
      this.removeOutsideClick();
    },
    removeOutsideClick() {
      document.removeEventListener("click", this.closeOnOutsideClick);
    },
    signout() {
      this.postToApi({
        url: "/logout",
        mutation: "setView"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  height: 3rem;
}

.pipe {
  border-left: 2px solid black;
  height: 2rem;
  align-self: center;
}

.settings-menu {
  position: absolute;
  display: block;
  height: auto;
  width: auto;
  top: 2.7rem;
  right: -0.5rem;
  z-index: 10;
}
</style>
