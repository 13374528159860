import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import ActivateAccount from "../views/ActivateAccount.vue";

import Home from "../views/Home.vue";
import PluginList from "../components/home/PluginList.vue";

import Login from "../views/Login.vue";
import LoginPartial from "../components/forms/partials/LoginPartial.vue";
import MfaLoginPartial from "../components/forms/partials/MfaLoginPartial.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    component: Login,
    children: [
      {
        path: "/",
        component: LoginPartial
      },
      {
        path: "mfa",
        component: MfaLoginPartial
      }
    ]
  },
  {
    path: "/activate-account",
    component: ActivateAccount
  },
  {
    path: "/home",
    component: Home,
    children: [
      {
        path: "/",
        component: PluginList
      },
      {
        path: "settings",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../components/home/Settings.vue"
          )
      },
      {
        path: "terms-of-service",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../components/TermsOfService.vue"
          )
      },
      {
        path: "settings/two-factor-authentication",
        component: () =>
          import(
            /* webpackChunkName: "Mfa" */ "../components/home/MfaSettings.vue"
          )
      }
    ]
  },
  {
    path: "/app/:path",
    component: () =>
      import(/* webpackChunkName: "AppView" */ "../views/AppView.vue")
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
