import { PostData } from "@/types";
import { Commit } from "vuex";

const createBody = (object: any): string => {
  const params = new URLSearchParams();
  params.append("browserRequest", "true");

  for (const key in object) {
    params.append(key, object[key].toString());
  }
  return params.toString();
};

export default {
  postToApi({ commit }: { commit: Commit }, postData: PostData) {
    fetch(postData.url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: createBody(postData.data)
    }).then(response => {
      commit(postData.mutation, response);
    });
  },
  postJsonToApi({ commit }: { commit: Commit }, postData: PostData) {
    fetch(postData.url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postData.data)
    }).then(response => {
      commit(postData.mutation, response);
    });
  },
  getFromApi({ commit }: { commit: Commit }, postData: PostData) {
    // make more generic later on. Switch on data payload for example
    fetch(postData.url, {
      method: "GET"
    }).then(response => {
      commit(postData.mutation, response);
    });
  }
};
