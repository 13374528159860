<template>
  <div>
    <div :key="plugin.id" v-for="plugin in plugins">
      <plugin-template :template="plugin.template" />
    </div>
  </div>
</template>

<script>
import PluginTemplate from "../PluginTemplate.vue";

export default {
  components: { PluginTemplate },
  data: function() {
    return {
      plugins: [
        {
          id: "Exact Online Plugin",
          template: `
    <div x-data="{
        data: {},
        removeState: false, 
        handleConnection: function() { 
          if(!this.removeState && this.data.active){
            this.removeState = true;
            return;
          }
          window.location.href = this.data.enableUrl;
        },
        removeConnection: async function() {
          const response = await fetch(this.data.disableUrl, { method: 'POST' });
          this.data = await response.json();
          this.removeState = false;
        },
        init: async () =>  { const response = await fetch('/exact-online/status'); return await response.json(); } 
        }"
        x-init="async () => data = await init()">
        <table class="mb-2 w-100">
        <thead class="border-bottom">
            <tr>
            <th></th>
            <th class="text-left">Status</th>
            <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td class="font-weight-bold pt-2">
                <span x-text="data.connector"></span>
            </td>
            <td class="text-left pt-2">
                <span x-text="data.active ? 'Connected' : 'Disabled'"></span>
            </td>
            <td class="text-right pt-2">
            <template x-if="!removeState">
                <button
                class='fluent'
                @click="handleConnection"
                x-text="data.active ? 'Disable' : 'Connect'"
                ></button>
            </template>
            <template x-if="removeState">
              <div>
                <button
                class="fluent ms-bgColor-sharedRed20 ms-fontColor-gray10 mr-1"
                @click="removeConnection"
                x-text="'Disable'"
                ></button>
                <button 
                class="fluent" 
                @click="removeState = false"
                x-text="'Cancel'">
                </button>
              </div>
            </template>
            </td>
            </tr>
        </tbody>
        </table>
    </div>
      `
        }
      ]
    };
  }
};
</script>
